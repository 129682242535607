<div fxLayout.lg="column" fxFlex.lg="100%">

    <div fxLayout.lg="column" fxFlex.xs="40%" fxLayoutAlign="center center" style="margin-top: 5%;">
        <!-- <a fragment="home" (click)="navigateSection('home')"> -->
        <img src=" assets/Logo.png" style="width:200px; ">


    </div>
    <div fxLayout.lg="column " fxFlex.lg="60% " fxLayoutGap="5px " style="padding-top: 30px; ">

        <!-- <div fxLayoutGap="5px ">
            <img src=" assets/page-image/facebook.png " style="width:64px; ">
            <img src=" assets/page-image/whatsapp.png " style="width:64px; ">
            <img src=" assets/page-image/linkedin.png " style="width:64px; ">

        </div> -->
        <p style="text-align: center; margin-top: 14px; font-size: 15px;font-family: Roboto; ">
            © 2020 All rights reserved to ERA-InfoTech Ltd.
        </p>
    </div>






</div>