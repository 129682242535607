<div class="img-fluid" fxLayout="row" fxLayoutAlign="space-evenly center">

    <div fxFlex="50% " style="padding-left:120px; padding-right:150px ">
        <h1>
            Online Exam Portal
        </h1>

        <br>

        <p>
            <span><img src="assets/arrows/arrow2.png"></span> Face Verification
        </p>
        <p>
            <span><img src="assets/arrows/arrow1.png"></span>Activity Details

        </p>
        <p>
            <span><img src="assets/arrows/arrow3.png"></span> Alert System
        </p>
        <p>
            <span><img src="assets/arrows/arrow2.png"></span> Written and MCQ Both Option
        </p>



    </div>
    <div fxFlex="50% " fxLayoutAlign="center center ">

        <img src=" assets/exam-portal-pic.png " style="width:600px; float: right; ">



    </div>


</div>
