<div class="img-fluid">
    <div fxLayoutAlign="space-evenly center" style="color: #1D1D4F;font-size:20px; padding:30px;">
        <img src=" assets/Logo.png " style="width:200px; padding-right: 100px;">


        <a mat-button></a>
        <a mat-button>Home</a>

        <a mat-button>Packages</a>
        <a mat-button (click)="contact()"> Contact Us </a>

        <!-- <a mat-button style="padding-right: 100px;"></a>
        <a mat-button class="nav-button">Home</a>
        <a mat-button class="nav-button">About us</a>
        <a mat-button class="nav-button">Procedures</a>
        <a mat-button class="nav-button">Services</a> -->
        <!-- <a fragment="services" (click)="navigateSection('services')" mat-button>Services</a> -->


        <!-- <a fragment="form" (click)="navigateToSection('form')" mat-button>Contact Us</a> -->
        <!-- <button mat-button class="button-shape" style="margin-right: 60px;">Contact Us</button> -->
        <div class="example-button-row">
            <button type="submit" (click)="applicantLogin()" style="background-color : #F18600; color: white; text-align: center; width: 125px; margin-right: 10px; " mat-raised-button>
        Talent login</button>

            <!-- <button mat-button class="button-shape " style="margin-right: 60px; "> LOGIN </button> -->
            <button type="submit " (click)="adminLogin() " style="background-color : #F18600; color: white; padding-left: 20px;width: 120px;" mat-raised-button>Employer login
        </button>
        </div>
    </div>

    <div fxLayout.lg="row " fxLayoutAlign="space-evenly center ">

        <div fxFlex.lg="60% " style="margin-top:3%; padding-left: 10%; ">
            <h1 style="text-align:center;font-size:48px; font-family: 'Manrope';color: #1D1D4F; ">REVAMP YOUR HIRING PLATFORM
            </h1>

            <!-- <h1 style="text-align:center;font-size:48px; font-weight:bold;font-family: 'Manrope';color: #1D1D4F;margin-top:30px; margin-bottom: 30px; ">
                SOLUTIONS
            </h1> -->

            <p style="text-align:center; font-size: 18px; font-family: 'Manrope';color: #1D1D4F; margin-top: 20px; ">
                Hire desired candidates, reduce cost, faster with the best online
                <br>
            </p>
            <p style="text-align:center; font-size: 18px; font-family: 'Manrope';color: #1D1D4F; ">
                recruitment solution in the market.</p>

            <br>


            <!-- <p style="text-align:center; font-size: 18px; font-family: 'Roboto';color: white; margin-bottom: 20px; ">
                This solution has been built and maintained by
                <a href="https://erainfotechbd.com/ "><img src="assets/page-image/era.png " alt=" " width="80px "></a>
            </p> -->
            <!-- <a fragment="form " (click)="navigateToSection( 'form') " style="background-color: #F18600; border-radius: 25px; border: 2px solid #FFFFFF; color: #FFFFFF; font-size: 13px; width:60%; " mat-button> DOWNLOAD GUIDE </a> -->
            <div class="example-button-class">

                <a fragment="form" (click)="navigateToSection('form')" id="center " mat-button class="prettyButton"> BOOK YOUR DEMO</a>

                <a fragment="services" (click)="navigateSection('services')" id="center " style="background-color: #F18600; border-radius: 25px; border: 2px solid #FFFFFF; color: #FFFFFF; font-size: 13px; left: 45%; " mat-button class="button-shape "> OUR SOLUTION</a>
            </div>

        </div>
        <div>
            <p style="text-align:center; font-size: 18px; font-family: 'Roboto';color: #1D1D4F; position: absolute; bottom: 0 ;left: 38%; ">
                Copyright © <a href="https://erainfotechbd.com/ "><img src="assets/eralogo.png " alt=" " width="80px "></a>
            </p>
        </div>




    </div>
</div>
