<div class="img-fluid" fxLayout="row" fxLayoutAlign="space-evenly center">

    <div fxFlex="50% " style="margin-top:3%;padding-left:120px; padding-right:150px ">
        <h1>
            Applicant Panel

        </h1>

        <br>

        <p>
            <span><img class="icon" src="assets/arrows/arrow1.png"></span>Personal Information
        </p>
        <p>
            <span><img src="assets/arrows/arrow2.png"></span> Education

        </p>
        <p>
            <span><img src="assets/arrows/arrow3.png"></span> Experience
        </p>
        <p>
            <span><img src="assets/arrows/arrow4.png"></span> Skill Matrix
        </p>
        <p>
            <span><img src="assets/arrows/arrow5.png"></span> Certification
        </p>
        <p>
            <span><img src="assets/arrows/arrow6.png"></span> Training History
        </p>



    </div>
    <div fxFlex="50% " fxLayoutAlign="center center " style="margin-top: 2% ">

        <img src=" assets/info-pic.png " style="width:600px; float: right; ">



    </div>


</div>