<br>
<br>
<br>
<div fxLayout.xs="column" fxFlex.xs="100%">


    <div fxLayout.xs="column" fxFlex.xs="40%" fxLayoutAlign="center center" style="padding-top: 3%; padding-bottom: 50px;">
        <img class="center" src=" assets/Platform.png " style="width:80%">

    </div>






</div>