import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }
  public navigateSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }

  applicantLogin(){
    window.open("https://talent.talent-troop.com");

  }
  adminLogin(){
    window.open("https://employer.talent-troop.com/");
  }
  contact(){
    window.open("https://erainfotechbd.com/contacts?item=32")
  }
}
