<div class="img-fluid">
    <div fxLayout.lg="row" fxLayoutAlign="space-evenly center">
        <div fxFlex.lg="50%" style="margin-top:2%; padding-left: 10%;">
            <h1 style="text-align:center;font-size:48px;font-weight: 100; font-family:'Manrope';color: #1D1D4F;">Book Your Demo Now
            </h1>



            <p style="text-align:center; font-size: 18px; font-family:'Manrope';color: #1D1D4F; margin-top: 20px;">
                Hire desired candidates, reduce spend, faster with the best online
                <br>
            </p>
            <p style="text-align:center; font-size: 18px; font-family:'Manrope';color: #1D1D4F;">
                recruitment solution in the market.</p>

            <br>

            <form [formGroup]="infoForm" ngSubmit="onSubmit(infoForm.value)">
                <div fxLayout="row">
                    <div fxLayout="row wrap" fxLayoutGap="10px" fxFlex="100%" fxLayoutAlign="flex-start">
                        <mat-form-field appearance="outline" fxFlex="1 1 calc(50% - 10px)" class="from-color">
                            <mat-label class="text-design">Name</mat-label>
                            <input class="input-design" type="text" matInput formControlName="name" />

                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="1 1 calc(50% - 10px)" class="from-color">
                            <mat-label class="text-design">Phone Number</mat-label>
                            <input class="input-design" type="text" matInput formControlName="phone" />

                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 calc(50% - 10px)" class="from-color">
                            <mat-label class="text-design">Email</mat-label>
                            <input class="input-design" type="text" matInput formControlName="email" />



                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 calc(50% - 10px)" class="from-color">
                            <mat-label class="text-design">Company Name</mat-label>
                            <input class="input-design" type="text" matInput formControlName="company_name" />

                        </mat-form-field>



                        <div mat-dialog-actions fxFlex="1 1 calc(50% - 10px)" fxLayoutAlign="center center" fxLayoutGap="10px">


                            <button type="submit" (click)="onSubmit()" style="background-color : #F18600; color: white;" mat-raised-button>
                    Save
                  </button>
                        </div>



                    </div>
                </div>
            </form>

            <a href="">Back to top</a>
            <br>
            <br>
        </div>
