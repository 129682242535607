import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  infoForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.infoForm = this.formBuilder.group({
      name: [''],
      phone: [''],
      email: [''],
      company_name: ['']
})



    
  }
  get name() {
    return this.infoForm.get('name');
  }
  get email() {
    return this.infoForm.get('email');
  }
  get company_name() {
    return this.infoForm.get('company_name');
  }
  get phone() {
    return this.infoForm.get('phone');
  }
  onSubmit(){}

}
