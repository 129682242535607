import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exam-portal',
  templateUrl: './exam-portal.component.html',
  styleUrls: ['./exam-portal.component.css']
})
export class ExamPortalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
