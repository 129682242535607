import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  
  }
  // public navigateSection(section: string) {
  //   window.location.hash = '';
  //   window.location.hash = section;
  // }

}
