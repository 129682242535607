<br>
<br>
<br>
<div fxLayout.xs="column" fxFlex.xs="100%">
  <div fxFlex.lg="100%">
        <h1 style="text-align:center;font-size:48px;font-weight: 100; font-family:'Manrope';color: #1D1D4F;">Unique Features</h1>
        <p style="text-align:center; font-size: 18px; font-family:'Manrope';color: #1D1D4F; margin-top: 20px;">
            Hire desired candidates, reduce spend, faster with the best online
            <br>
        </p>
        <p style="text-align:center; font-size: 18px; font-family:'Manrope';color: #1D1D4F;">
            recruitment solution in the market.</p>
        <br>
    </div>
</div>

<div fxLayout.xs="column" fxFlex.xs="100%">

    <div fxLayout.xs="column" fxFlex.xs="40%" fxLayoutAlign="center center" style="padding-top: 2%; padding-bottom: 50px;">
      <img class="center" src="assets/unique_features.png" style="width:80%">
    </div>

    <a style="margin-left: 150px;" href="">Back to top</a>
