<div class="banner" fxLayout.lg="row" fxLayoutAlign="space-evenly center">
    <div fxFlex.lg="50%" fxLayoutAlign="center center" style="margin-top: 2%; margin-right: 40px">
        <img src=" assets/admin-panel-pic.png" style="width: 400px" />
    </div>

    <div fxFlex.lg="60%" style="margin-top: 100px; padding-left: 120px; padding-right: 100px">
        <h1>HR Panel</h1>

        <p>
            <span><img src="assets/arrows/arrow1.png" /></span> Hiring Announcement
        </p>
        <p>





            <span><img src="assets/arrows/arrow2.png" /></span> Policy Configuration
        </p>
        <p>
            <span><img src="assets/arrows/arrow3.png" /></span> Exam Setup
        </p>
        <p>
            <span><img src="assets/arrows/arrow4.png" /></span> Online or Offline Exam
        </p>
        <p>
            <span><img src="assets/arrows/arrow5.png" /></span> Approval
        </p>
        <p>
            <span><img src="assets/arrows/arrow6.png" /></span> User Defined Multiple Set of Question
        </p>
        <p>
            <span><img src="assets/arrows/arrow7.png" /></span>File Upload Facilities
        </p>
        <p>
            <span><img src="assets/arrows/arrow1.png" /></span> Dynamic Selection Process

        </p>

    </div>

</div>
